import { useContext, useState } from "react"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog"
import { httpRequest } from "utils/httpsRequest"
import { Context } from "context/GlobalState"


function getTrialKeys(obj, isTrial) {
    if (!obj) return []
    return Object.keys(obj).filter(key => isTrial ? key.includes('trial') : !key.includes('trial'))
    .map(key => ({
        name: key,
        value: obj[key]
    }));
}

const combineResources = (overwrittenResourceObj, resourceObj) => {
    const combinedResult = [];
  
    // Loop through resourceObj array
    resourceObj.forEach(resource => {
      // Find if this resource has been overwritten
      const overwrittenResource = overwrittenResourceObj.resources.find(
        overwritten => overwritten.resourceName === resource.name
      );
  
      // Combine resources with or without overwriting
      combinedResult.push({
        resourceName: resource.name,
        resourceValue: resource.value,
        resourceOverWrittenValue: overwrittenResource ? overwrittenResource.resourceOverwritten : null
      });
    });
  
    return combinedResult;
};

export default function SubOrgResourceDetail({ subOrg }) {

    const isTrial = subOrg?.subOrgResource?.isTrial
    const fields = getTrialKeys(subOrg?.subOrgResource?.subscriptionResource, isTrial)
    const combinedField = combineResources(subOrg?.subOrgResource.overwrittenResource, fields)
    
    return (
        <Card>
            <CardHeader>
                <CardTitle> Resources</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="grid gap-6">
                    <div className="flex items-center justify-between">
                        <h2 className="text-xl font-semibold">Resource Limits</h2>
                    </div>
                    <div className="grid gap-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            {combinedField.map((item) => (
                                <ResourceField key={item.resourceName} {...item} subOrg={subOrg} />
                            ))}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

const ResourceField = ({ resourceName, resourceValue, resourceOverWrittenValue, subOrg }) => {

    const [editableResourceValue, setEditableResourceValue] = useState(resourceOverWrittenValue)
    const [open, setOpen] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { addNewNotifcation } = useContext(Context)


    const handleSubmit = () => {
        const resourceObj = {
            organizationId: subOrg.organizationId,
            subOrganizationId: subOrg._id,
            resourceName: resourceName,
            resourceOverwritten: editableResourceValue,
        }
        
        setBtnLoading(true)
        httpRequest.post('/admin/overwritten-resource/overwrite-organization-resource', resourceObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Resource updated successfully', 'success')
            setOpen(false)
        }).catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    // Convert resourceValue to a number if it's numeric, otherwise leave as is
    const parsedResourceValue = isNaN(resourceValue) ? resourceValue : Number(resourceValue);

    // If resourceOverWrittenValue is not null, undefined, or NaN, use it and add it to resourceValue
    const finalValue = editableResourceValue != null && !isNaN(editableResourceValue)
        ? parsedResourceValue + Number(editableResourceValue)
        : parsedResourceValue;

    return (
        <div>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button onClick={() => setOpen(true)} variant="outline" className="flex gap-2 text-xs">
                        <span> {resourceName} </span>
                        <span className="text-muted-foreground text-[12px]"> {finalValue} </span>
                    </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-md">
                    <DialogHeader>
                        <DialogTitle>Modify {resourceName} Resource</DialogTitle>
                    </DialogHeader>
                    <div className="flex flex-col items-center space-x-2">
                        <div className="space-y-2 w-full">
                            <span> {resourceName} </span>
                            <span className="text-muted-foreground text-[12px]"> {`(${resourceValue} + ${editableResourceValue})`} </span>
                        </div>

                        <div className="space-y-2 w-full">
                            <Label htmlFor={'plan-resource'}> Plan Resource </Label>
                            <Input
                            id={'plan-resource'}
                            type="number"
                            defaultValue={resourceValue}
                            disabled={true}
                            className="mt-1"
                            />
                            {/* <Input value={newFolderName} onChange={(e) => setNewFolderName(e.target.value)} /> */}
                        </div>
                        <div className="space-y-2 w-full">
                            <Label htmlFor={'additional-resource'}>Additional Resource</Label>
                            <Input
                            id={'additional-resource'}
                            type="number"
                            value={editableResourceValue || 0}
                            onChange={(e) => setEditableResourceValue(e.target.value)}
                            className="mt-1"
                            />
                        </div>
                    </div>
                    <DialogFooter className="flex justify-end w-full">
                        <DialogClose asChild>
                            <Button type="button" variant="secondary">
                                Close
                            </Button>
                        </DialogClose>
                        <Button disabled={btnLoading} onClick={handleSubmit} type="button" variant="">
                            Submit
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    )
}