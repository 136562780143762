import { Folder, ChevronDown, ChevronRight, Trash, Ellipsis } from 'lucide-react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Button } from 'components/ui/button';

const FileTreeItem = ({ item, onSelect, selectedFolder, expandedFolders, onToggleExpand, handleDeleteModal, isRoot }) => {
    if (item.type === "file") return null;

    const isExpanded = expandedFolders.includes(item.id);

    return (
        <div className="flex flex-col">
            {/* Main container for each folder row */}
            <div className="relative flex items-center my-1 cursor-pointer" onClick={() => onSelect(item)}>
                
                <div
                    className={`absolute inset-0 h-full w-full rounded-sm ${selectedFolder === item.id ? "bg-gray-100" : "hover:bg-gray-100"}`}
                />

                <div className="relative flex items-center w-full z-10 h-8 px-1 text-xs">
                    <div className="flex items-center w-fit min-w-[200px]">
                        <span
                            className="mr-1 w-4 h-4 flex items-center justify-center"
                            onClick={(e) => {
                                e.stopPropagation();
                                onToggleExpand(item.id);
                            }}
                        >
                            {item.children && item.children.some(child => child.type === "folder") ? 
                                (isExpanded ? (
                                    <ChevronDown className="w-4 h-4" />
                                ) : (
                                    <ChevronRight className="w-4 h-4" />
                                )) : (
                                    <ChevronRight className="w-4 h-4 text-transparent" />
                                )}
                        </span>
                        <Folder className="text-yellow-600 w-4 h-4 mr-2 flex-none" />
                        <span className="w-full">{item.name}</span>
                    </div>

                    {/* Conditionally render the action button only for subfolders */}
                    {!isRoot && (
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                                    <Ellipsis className="h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteModal([item]);
                                    }}
                                >
                                    <Trash className="w-4 h-4 mr-2" />
                                    <span>Delete</span>
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    )}
                </div>
            </div>

            {isExpanded && item.children && (
                <div className="ml-4">
                    {item.children.map((child) => (
                        <FileTreeItem
                            key={child.id}
                            item={child}
                            onSelect={onSelect}
                            selectedFolder={selectedFolder}
                            expandedFolders={expandedFolders}
                            onToggleExpand={onToggleExpand}
                            handleDeleteModal={handleDeleteModal} 
                            isRoot={false} 
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FileTreeItem;
