/* eslint-disable max-lines */
import React, {useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RiRobot2Line } from "react-icons/ri";
import { LuSendHorizonal } from "react-icons/lu";
import { VscClearAll } from "react-icons/vsc";
import { httpRequestO } from "../../../utils/httpsRequest";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import renderers from "./renderers";
import { useParams } from "react-router-dom";
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px auto 0px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 700px;
  height: 500px;
  bottom: 20px;
  right: 20px;

  .custom-markdown-webDemo table {
    font-size: 0.8rem;
    border-collapse: collapse;
    width: 100%;
  }
  .custom-markdown-webDemo th, .custom-markdown-webDemo td {
    padding: 0.5rem;
    text-align: left;
    border: 1px solid #ddd;
  }
  .custom-markdown-webDemo th {
    background-color: #aaaaaa;
    color: white;
  }
  .custom-markdown-webDemo table tr:first-child th {
    border-top: 0;
  }
  .custom-markdown-webDemo table tr:last-child td {
    border-bottom: 0;
  }
  .custom-markdown-webDemo table tr td:first-child,
  .custom-markdown-webDemo table tr th:first-child {
    border-left: 0;
  }
  .custom-markdown-webDemo table tr td:last-child,
  .custom-markdown-webDemo table tr th:last-child {
    border-right: 0;
  }
  .custom-markdown-webDemo ol {
    list-style-type: decimal;
  }
  .custom-markdown-webDemo ul {
    list-style-type: disc;
  }
`;

const Header = styled.div`
  background-color: white;
  color: black;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #ccc;
`;

const MessageItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  align-items: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  justify-content: ${(props) => (props.isUser ? "right" : "left")};
  flex-direction: ${(props) => (props.isUser ? "row-reverse" : "row")};
`;

const MessageContent = styled.div`
  background-color: ${(props) => (props.isUser ? "#e0e0e0" : "#f1f1f1")};
  color: #333;
  padding: 10px;
  border-radius: 18px;
  max-width: 85%;
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  margin-left: ${(props) => (props.isUser ? "30px" : "0")};
  margin-right: ${(props) => (props.isUser ? "0" : "30px")};
  word-break: break-word; 
  overflow-wrap: anywhere; 
  box-sizing: border-box;

  ol, ul {
    padding-left: 20px; 
    margin: 5px 0; 
    list-style-position: inside;  
  }
`;


const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
  max-width: 100%;
`;


const Avatar = styled.div`
  width: 32px;
  height: 32px;
  background-color: #6f6f6f;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 10px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const MessageInput = styled.input`
  flex: 1;
  padding: 10px;
  border: red;
  outline: none;
  font-size: 14px;
  background-color: white;
  border-radius: 18px;
  padding-left: 15px;
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
`;

const SendIcon = styled.span`
  font-size: 18px;
  color: grey;
`;

const TypingLoader = styled.div`
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  height: 20px;
  margin: 5px 0;
  padding-left: 10px; 

  div {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #b1092a;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }

  div:nth-child(1) {
    animation-delay: -0.32s;
  }
  div:nth-child(2) {
    animation-delay: -0.16s;
  }
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
`;


const ClearChatDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DialogButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  ${(props) => (props.confirm ? 'background-color: #b1092a; color: white;' : 'background-color: #393939; color: white;')}
`;

const WebChat = ({ selectedDocument, uploadFilter, modelPrompt, decodingMethod, modelIdName, maxTokens }) => {
  const [messages, setMessages] = useState([{ content: "Hi, I am BeX Assistant Digital Concierge. How can I help you today?", role: "assistant" }]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showClearChatDialog, setShowClearChatDialog] = useState(false);
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  const {subOragID, oragID} = useParams();

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages, isLoading]);

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const MessageConstructor = (messages) => {
    let constructedMessage = messages.map(message => `${message.role === "user" ? "user " : "assistant"}\n${message.content}`).join("\n");
    return constructedMessage;
  };

  const handleQuery = async () => {
  
    let fromRag = "";
    console.log('Selected Document:', selectedDocument);
    
    try {
      if (selectedDocument.length < 1) {
        const formData = new FormData();
        formData.append('organization_id', oragID);
        formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
        formData.append("pre_filter", JSON.stringify(uploadFilter));
        formData.append('k', '15');
        formData.append('query', "");

    
        const result = await httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData);
        console.log('API Result Data:', result.data);
        console.log('Form Data:', formData);
        
        if (result.data.data && result.data.data.documents) {
          for (let pageContent of result.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        } else {
          console.log('No documents found in result data.');
        }
      } else {
        const promiseArr = [];
        for (const [index, document] of selectedDocument.entries()) {
          console.log("QUERYING", document)
          const formData = new FormData();
          formData.append('organization_id', document.documentRecord.organization_id);
          formData.append('sub_organization_id', document.documentRecord.sub_organization_id);
          formData.append('document_id', document.documentRecord._id);
          formData.append('k', 15);
          formData.append('query', inputMessage);
          promiseArr.push(httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData));
        }
        
        const resp = await Promise.all(promiseArr);
        console.log(resp)
        console.log('API Responses:', resp);
        
        for (let documentResult of resp) {
          if (documentResult.data.data && documentResult.data.data.documents) {
            for (let pageContent of documentResult.data.data.documents) {
              fromRag += `[Document]\n`;
              fromRag += `document Name: ${selectedDocument.find(doc => (doc.documentRecord._id === pageContent.metadata.document_id)).name}\n`;
              fromRag += `${pageContent.page_content}\n`;
              fromRag += `[End]\n`;
            }
          } else {
            console.log('No documents found in response data for one of the selected documents.');
          }
        }
      }
      
      return fromRag;
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
  
    const userMessage = {
      content: inputMessage,
      role: "user",
    };
  
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setInputMessage("");
    setIsLoading(true);
  
    try {
      const relatedPassages = await handleQuery();
  
      let fullPrompt = `${modelPrompt ? `${modelPrompt}` : "Please respond to the user’s question directly and concisely based only on the information provided in the following document context. If the requested information is not available in the document context, respond with: I'm sorry, but I don't have the information you're looking for at the moment. Please try rephrasing your question or contact support for further assistance. Avoid guessing or generating responses not explicitly stated in the document."}
      
      Do not generate any unncessary input and output samples.
      
      Document Context:
      ${relatedPassages ? `${relatedPassages}` : ""}
      `;
        
      const requestData = {
        modelId: modelIdName, 
        parameters: {
          max_new_tokens: maxTokens || 900, 
          response_format: { type: "text" },
          temperature: decodingMethod === "sampling" ? 0.7 : 0, 
          seed: 42,
          stop_sequence: "Input:"
        },
        prompt: fullPrompt,
        query: inputMessage, 
      };
  
      console.log("Dynamic Request Data:", requestData);
      setIsLoading(true);
      const model_type = "IBM";  

      const response = await unifiedModelService.Experiment(requestData, model_type);
      console.log("API Response:", response);
  
      const prediction = response?.data?.result?.prediction;

      if (prediction) {
      const botReply = prediction ? prediction.trim() : "I'm sorry, but there was an error processing your request.";
  
      const newMessage = {
        content: botReply,
        role: "assistant",
      };
  
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsLoading(false);
    } else {
      setTimeout(handleSendMessage, 5000);
    }

    } catch (error) {
      console.error("Error fetching response:", error);
      const errorMessage = {
        content: "I'm sorry, but there was an error processing your request. Please check the selected use case and loaded document if they match. Please try again.",
        role: "assistant",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnterPressed = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleClearChatClick = () => {
    setShowClearChatDialog(true);
  };

  const handleClearChat = () => {
    setShowClearChatDialog(false);
    setMessages([{ content: "Hi, I am BeX Assistant Digital Concierge. How can I help you today?", role: "assistant" }]);
  };

  const handleContinueChat = () => {
    setShowClearChatDialog(false);
  };

  return (
      <>
          <ChatContainer>
              <Header>
                  <span>Bex AI Assistant</span>
                  <button onClick={handleClearChatClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'black', textDecoration: 'none' }}><VscClearAll /></button>
              </Header>
              <MessageList ref={messageListRef}>
                  {messages.map((message, index) => (
                      <MessageItem key={index} isUser={message.role === "user"}>
                          {message.role !== "user" && <Avatar><RiRobot2Line /></Avatar>}
                          <MessageContent isUser={message.role === "user"}>
                              <Markdown className={"custom-markdown-webDemo"} components={renderers} remarkPlugins={[remarkGfm]}>
                                  {message.content}
                              </Markdown>
                          </MessageContent>
                      </MessageItem>
          ))}
                  {isLoading && (
                      <TypingLoader>
                          <div></div>
                          <div></div>
                          <div></div>
                      </TypingLoader>
          )}
              </MessageList>
              <InputContainer>
                  <MessageInput
            ref={inputRef}
            type="text"
            placeholder="Type something..."
            value={inputMessage}
            onChange={handleInputChange}
            onKeyDown={handleEnterPressed}
          />
                  <SendButton onClick={handleSendMessage}>
                      <SendIcon><LuSendHorizonal /></SendIcon>
                  </SendButton>
              </InputContainer>
          </ChatContainer>
          {showClearChatDialog && (
          <ClearChatDialog>
              <h3>End Chat</h3>
              <p>Are you sure you want to clear the chat?</p>
              <div>
                  <DialogButton confirm onClick={handleClearChat}>
                      Yes
                  </DialogButton>
                  <DialogButton onClick={handleContinueChat}>
                      No
                  </DialogButton>
              </div>
          </ClearChatDialog>
      )}
      </>
  );
};

export default WebChat;