import { Folder, File, Ellipsis } from 'lucide-react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Badge } from '@mui/material';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';

const FileListTable = ({ selectedFolderContent, findFolder, handleSelect, getFileSystem, rowActions = [], selectedItems, setSelectedItems, showFailed }) => {
    const filesOnly = selectedFolderContent.filter(item => item.type === 'file');
    const foldersOnly = selectedFolderContent.filter(item => item.type === 'folder');

    const toggleSelectAll = (checked) => {
        if (checked) {
            setSelectedItems(filesOnly);
        } else {
            setSelectedItems([]);
        }
    };

    const toggleSelectItem = (item, checked) => {
        if (checked) {
            setSelectedItems(prev => [...prev, item]);
        } else {
            setSelectedItems(prev => prev.filter(selectedItem => selectedItem.id !== item.id));
        }
    };

    const actionCondition = (item) => {
        return true; // Currently always showing the actions
    };

    return (
        <div>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-12">
                            <Checkbox
                                checked={selectedItems.length === filesOnly.length && filesOnly.length > 0}
                                onCheckedChange={toggleSelectAll}
                                aria-label="Select all"
                            />
                        </TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead className="min-w-[24px]">Type</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead className="min-w-[24px]">Action</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className="overflow-y-scroll">
                    {filesOnly.length === 0 && foldersOnly.length > 0 ? (
                        <TableRow>
                            <TableCell colSpan={5} className="text-center py-4">
                                <div className="flex items-center justify-center h-[220px]">
                                    No files here
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : (
                        filesOnly
                            .filter(item => showFailed || item.status !== 'failed') 
                            .map((item) => (
                                <TableRow key={item.id} className={item.status === "failed" && !showFailed ? `hidden` : ""}>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedItems.some(selectedItem => selectedItem.id === item.id)}
                                            onCheckedChange={(checked) => toggleSelectItem(item, checked)}
                                            aria-label={`Select ${item.name}`}
                                        />
                                    </TableCell>
                                    <TableCell className="max-xl:max-w-[100px] max-w-[200px]">
                                        <div className="flex items-center space-x-2 text-xs">
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <div className="flex items-center truncate">
                                                            <File className="w-4 h-4 flex-shrink-0 mr-2" />
                                                            <a target="_blank" href={item.presigned_url} className="truncate">
                                                                {item.name} 
                                                            </a>
                                                        </div>
                                                    </TooltipTrigger>
                                                    <TooltipContent className="bg-gray-900 text-white w-fit">
                                                        <p>{item.name}</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                    </TableCell>
                                    <TableCell className="min-w-[24px] max-w-[24px]">
                                        {item.type}
                                    </TableCell>
                                    <TableCell className="w-[200px]">
                                        {item.type === 'file' ? (
                                            <>
                                                {item.status === 'success' ? (
                                                    <Badge variant="outline" className="px-1 rounded bg-green-500 text-green-50">
                                                        {item.status}
                                                    </Badge>
                                                ) : item.status === 'failed' ? (
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <div>
                                                                    <Badge variant="outline" className="px-1 rounded bg-red-500 text-red-50">
                                                                        {item.status}
                                                                    </Badge>
                                                                    <div className="text-xs max-lg:w-[100px] max-w-[200px] truncate text-muted-foreground">
                                                                        {item.reason}
                                                                    </div>
                                                                </div>
                                                            </TooltipTrigger>
                                                            {item.reason && (
                                                                <TooltipContent className="bg-gray-900 text-white">
                                                                    <p>{item.reason}</p>
                                                                </TooltipContent>
                                                            )}
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                ) : (
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <div>
                                                                    <Badge variant="outline" className="px-1 rounded bg-yellow-500 text-yellow-50">
                                                                        {item.status}
                                                                    </Badge>
                                                                    <div className="text-xs max-w-[200px] truncate text-muted-foreground">
                                                                        {item.reason}
                                                                    </div>
                                                                </div>
                                                            </TooltipTrigger>
                                                            {item.reason && (
                                                                <TooltipContent className="bg-gray-900 text-white">
                                                                    <p>{item.reason}</p>
                                                                </TooltipContent>
                                                            )}
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                )}
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </TableCell>
                                    {actionCondition(item) && (
                                        <TableCell className="min-w-[24px] max-w-[24px]">
                                            <DropdownMenu>
                                                <DropdownMenuTrigger asChild>
                                                    <Button aria-haspopup="true" size="icon" variant="ghost" className="border-none bg-transparent">
                                                        <Ellipsis className="h-4 w-4" />
                                                    </Button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent align="end">
                                                    {rowActions.map((action) => (
                                                        <DropdownMenuItem
                                                            key={action.name}
                                                            onClick={() => action.onclick([item])}
                                                        >
                                                            {action.icon}
                                                            <span className="ml-2">{action.name}</span>
                                                        </DropdownMenuItem>
                                                    ))}
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default FileListTable;
