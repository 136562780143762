/* eslint-disable max-lines */
import { httpRequestO } from "utils/httpsRequest";
import { ragService } from "api/services/PROJECT-O";
import { createApiClient } from "api/config";
import { unifiedModelService } from 'api/services/PROJECT-O/UnifiedModel.service';
import { milvusService } from "api/services/PROJECT-O/Milvus.service";
import { useParams } from 'react-router-dom';

class HotelOpsHelper {

    getPromptByMode(mode, ConstructedRAG, input, config) {
        let prompt;
    
        switch (mode) {
            case "query":
                prompt = `You are in Query Mode. Answer the questions based on the provided context only.
                        Please provide the most accurate response based on the question.
                        Respond in a friendly and professional manner.
                        If information is not in context, do not make up information and respond with "I 
                        can't find that information. Can you rephrase that?". Do not add any reference link if
                        there's no answer in the context.
                        Context:\n${ConstructedRAG}\nQuestion: ${input}`;
                break;
            case "expert":
                prompt = `You are in Fireside Chat Expert Mode. You are tasked to share your expertise in a friendly and conversational way. Based on 
                this context and knowledge: ${ConstructedRAG}\n 
                Question: ${input} Generate an answer for this question in simple, everyday language like you are talking to someone. Strictly Generate the answer in a 
                very conversational tone, but still show that you're an expert on the topic. Show excitement on the tone about the topic, relate the topic to everyday life, but 
                stay clear and concise. Do not just define based on context.`;
                break;
            case "quiz":
                prompt = `You are in Quiz Mode, specialized in generating Quizzes. 
                        When responding, follow these guidelines:

                        1. Always include the page number and document title where the answers are found for EVERY QUESTION.
                        2. Always include the correct answer.
                        3. Multiple choice types shall have multiple choices and not one only.
                        4. Each item shall strictly have one correct answer only.
                        5. Avoid repeating items or phrases.
                        6. Ensure your responses are concise and grounded on the document's context.
                          Ensure questions test the user's knowledge accurately.
                          Context:\n${ConstructedRAG}\nQuestion: ${input} Users might give a topic and your task is to always generate a quiz.`;
                break;
            case "teacher":
                prompt = `You are in Teacher Mode. Explain the concept from the context then add a statement to encourage students to ask questions and think critically, 
                and provide explanations that are clear, structured, and relatable. Imagine you’re teaching a classroom of students who are 
                eager to learn but may have varying levels of familiarity with the subject. Use examples, analogies, metaphors and questions to make 
                the explanation understandable. If appropriate, break down the information into smaller, digestible parts. 
                          Context:\n${ConstructedRAG}\nQuestion: ${input} Ask follow-up questions at the end of response to spark curiosity and critical thinking to user.`;
                break;
            case "student":
                prompt = `You are in Student Mode, acting as an eager and curious learner. Ask questions to 
                encourage the user to explain the topic, as if they are teaching you. Show genuine curiosity, and ask them to 
                clarify complex ideas or elaborate on key points. Ask thoughtful questions to prompt the user to explain concepts or 
                provide examples. Show interest in understanding the topic deeply, as if you’re eager to learn from the user. Use a 
                friendly, curious tone that encourages the user to take on the role of a teacher. Avoid giving information yourself; 
                instead, ask questions from the context that guide the user to explore the topic further.
                          Context:\n${ConstructedRAG}\nQuestion: ${input} Ask questions to the user about a specific topic.`;
                break;
            default:
                prompt = `Default prompt. Use the context to respond accurately.
                          Context:\n${ConstructedRAG}\nQuestion: ${input}`;
                break;
        }
    
        prompt += `\n\nRespond with format: ${config.outputFormat}, and ${
            config.includeReferences ? 'include references' : 'do not include references'
        }.\nWord limit: ${config.wordLimit} words.`;
    
        return prompt;
    }    

    // Process Multiplee AI Lab Docs 
    async ProcessFiles(files, filter, org, suborg, bucket, chunk_size = 200, chunk_overlap = 20) {
        try {
            const results = await Promise.all(files.map(async (file, index) => {
                const form = new FormData();

                form.append('organization_id', org);
                form.append('sub_organization_id', suborg);
                form.append('bucket_name', bucket);
                form.append('chunk_size', chunk_size);
                form.append('chunk_overlap', chunk_overlap);

                // Make sure the extension is lowercased
                const newFile = this.toLowerCaseExtension(file);
                form.append('files', newFile);

                let filter2 = { ...filter };
                filter2.name = newFile.name;

                form.append('metadata', JSON.stringify(filter2));

                try {
                    const res = await ragService.processFile(form);

                    if (!res.data || res.data.document_ids.length === 0) {
                        console.error(`No document IDs received for file at index ${index}.`);
                        return null;
                    }

                    return res.data;
                } catch (error) {
                    console.error(`Error processing file at index ${index}:`, error);
                    return null;
                }
            }));
            
            return results.filter(result => result !== null && result !== undefined);
        } catch (error) {
            console.log("FILE PROCESSING ERROR", error);
            return [];
        }
    }
     
    async QueryAllDocs(filter, query, org, suborg){
        try{
            const form = new FormData();
            form.append('organization_id', org);
            form.append('sub_organization_id', suborg);
            form.append('query', query);
            form.append('pre_filter', JSON.stringify(filter));
            form.append('k', 15);
            const res = await httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, form);

            console.log("Query ALL API Response: ", res);
            return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    async QueryEmbeddings(query, org, suborg, collectionId){
        try{
            const body = {
                "organziation_id": org,
                "sub_organization_id": suborg,
                "collection_id": collectionId,
                "filters": {},
                "query_text": query,
                "top_k": 5,
                "metric_type": "COSINE"
              }

              console.log("Payload being sent to API: ", body);  
              const res = await milvusService.QueryEmbeddings(body)
              console.log("QueryEmbeddings Result: ", res);
              return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    async QueryAndRerank(query){
        try{
            const body = {
                "collection_name": "test_sync_conf",
                "query_text": query,
                "top_k": 10,
                "top_n": 3
            }
            const res = await milvusService.QueryAndRerank(body)
            return res;
        }
        catch(error){
            console.log("ERROR: ", error);
        }
    }

    constructMilvusRetrievedResults = async (docs, response) => {
        let str = "";
    
        console.log("Full API Response:", response);
    
        const presignedUrls = response?.data?.presigned_urls;
    
        console.log("Presigned URLs:", presignedUrls);
    
        if (!presignedUrls || typeof presignedUrls !== 'object') {
            console.error("Invalid presignedUrls format. It should be an object.");
            return "";
        }
    
        for (const doc of docs) {
            const fileKey = doc.file_key;
            let link = "";
    
            if (Object.prototype.hasOwnProperty.call(presignedUrls, fileKey)) {
                link = `${presignedUrls[fileKey]}#page=${doc.page_number}`;
                const fileExtension = fileKey.split('.').pop().toLowerCase();
    
                if (['txt', 'docx', 'xlsx', 'pptx', 'ppt', 'doc', 'xls'].includes(fileExtension)) {
                    link = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(link)}`;
                }
    
                console.log(link);
            } else {    
                console.log(`No pre-signed URL found for ${fileKey}`);
            }
    
            const fileName = fileKey.split('/').pop();
    
            str += "[Document]\n";
            str += link ? `[Reference Link]${link}[/Reference Link]\n` : "";
    
            const fileExtension = fileKey.split('.').pop().toLowerCase();
            console.log("This is file ext", fileExtension)
            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                if (doc.sheet_name) {
                    str += `[Sheet]${doc.sheet_name}[/Sheet]\n`; 
                } else {
                    console.error("Missing sheet_number for Excel file");
                }
            } else {
                str += `[Page]${doc.page_number}[/Page]\n`; 
            }
    
            str += `[Date Created]${doc.created_at}[/Date Created]\n`;
            str += `[Title]${fileName}[/Title]\n`;
            str += `[Content]${doc.text}[/Content]\n`;
            str += "[/Document]\n\n";
        }
    
        return str;
    };

    constructMongoDBRetrievedResults(docs, uploadedDocs) {
        let str = "";
    
        docs.forEach((doc) => {
            const ext = this.getFileExtension(doc.metadata.name).toLowerCase();
            const isOfficeFile = ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls'].includes(ext);
            const isPdf = ext === "pdf";

            const match = uploadedDocs.find(item => item.name === doc.metadata.name);
            let link = "";
    
            if (match) {
                if (isPdf) {
                    link = `${match.file_url}#page=${doc.metadata.start_page}`;
                } else if (isOfficeFile) {
                    link = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(match.file_url)}`;
                } 
                console.log("These are the links retrieved", link); 
            } else {
                console.log(`No matching document for ${doc.metadata.name}`);
            }
    
            str += "[Document]\n";
            str += `Reference Link: ${link}\n`;
            str += (ext === 'ppt' || ext === 'pptx') ? `[Page]${doc.metadata.slide_number}[/Page]\n` : "";
            str += (isPdf || ext === 'docx') ? `[Page]${doc.metadata.start_page}[/Page]\n` : "";
            str += `[Title]${doc.metadata.name}[/Title]\n`;
            str += `[Content]${doc.page_content}[/Content]\n`;
            str += "[/Document]\n\n";
        });
    
        return str;
    }
    
    
    getFileExtension(url) {
        // eslint-disable-next-line no-useless-escape
        return url.split('.').pop().split(/\#|\?/)[0];
    }

    toLowerCaseExtension(file) {
        const { name, type } = file;
        const lastDotIndex = name.lastIndexOf('.');
        if (lastDotIndex === -1) return file;
        const newFileName = `${name.slice(0, lastDotIndex)}.${name.slice(lastDotIndex + 1).toLowerCase()}`;
    
        // eslint-disable-next-line no-undef
        return new File([file], newFileName, { type });
    }

    // Groq
    async ConstructPromptGroq(prompt, input, config) {
        input = 
        `Question: ${input}

        If input is in different language, translate the context. Respond with Markdown format.

        Strictly base your response with the following:
        Format: ${config.outputFormat}, and
        ${config.includeReferences ? 'Include references' : 'Do not include reference links'}
                                                                                                             
        If mentioned to include references, Strictly respond with a reference link of the information in this format: "\n \n **Reference/s:** [Document Title](Reference_Link#page=start_page) - Page # or Sheet Name". Note: the Reference Link is in the context. Get that link exactly as it is.
        The reference link should be renamed with the Document Title. Ensure that the reference link is from constructRetrievedResults, clickable, valid and not changed. Otherwise, do not add the reference link.
        If the answer generated did not come from any link, add "Reference Link not available". If the response is a quiz, add a reference link where is the answer located for that specific question.

        If the answer is not in the ConstructedRAG , do not generate any answer and strictly respond with "I can't find that information. Can you rephrase that?"

        Strictly limit the response to a maximum of ${config.wordLimit} words only. Do not exceed. Summarize the response if necessary to stay within the limit.
        If the information is too lengthy, prioritize key points, and strictly keep the word count at or below the limit. (Word limit does not include links)
        At the end of the response generation, recheck the word count. If it exceeds ${config.wordLimit} words, trim the response to meet the word limit before finalizing.`;
        // eslint-disable-next-line max-lines

        console.log("This is the prompt", prompt);
        console.log("This is the input query", input);
        try{
            const body = {
                //modelId: "mixtral-8x7b-32768",
                //modelId: "llama-3.1-70b-versatile",
                 //modelId: "llama-guard-3-8b",
                // modelId:"llama-3.1-8b-instant",
                // parameters: {
                //     max_tokens: 900,
                //     response_format: {"type": "text"},
                //     temperature: 0
                // },
                modelId: 'llama-3.1-70b-versatile',
            parameters: {
                max_tokens: 900,
                response_format: {"type": "text"},
                temperature: 0,
                seed: 42
            },
                prompt: prompt,
                query: input
            }

            console.log("IBM payload", body)

            const model_type = "Groq";  
            const response = await unifiedModelService.Experiment(body, model_type);
            //console.log("IBM API Response:", response);
            return response;



        } catch(error){
            console.log("ERROR: ", error);
        }

        
    }

}
const hoHelper = new HotelOpsHelper()
export default hoHelper;