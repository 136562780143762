import React, { useEffect, useState } from 'react'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './ui/alert-dialog'
import { Button } from './ui/button'
import { Input } from './ui/input'

export default function ConfirmDeleteAlert({ isDialogOpen, setIsDialogOpen, deleteLoading, name, handleDeleteOrganization }) {

    const [inputCheckName, setInputCheckName] = useState('')


    useEffect(() => {
        setInputCheckName('')
    }, [isDialogOpen])

    return (
        <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                        This action cannot be undone. This will permanently delete &quot;{name}&quot;.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <div className="my-4 flex flex-col">
                    <Input name='name' value={inputCheckName} onChange={(e) => setInputCheckName(e.target.value)} />
                    <span className="text-sm text-muted-foreground"> Write the name  &quot;{name}&quot; to confirm </span>
                </div>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <Button variant="destructive"  onClick={handleDeleteOrganization} disabled={deleteLoading || inputCheckName !== name}>
                        Permanently Delete
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
